import React from 'react';
import marked from 'marked';
import hljs from 'highlight.js';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom';

import style from './blog.module.css';
import 'highlight.js/styles/github.css';

const option ={
    renderer: new marked.Renderer(),
    highlight: function(code){
        return hljs.highlightAuto(code).value;
    },
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false
}

function BlogComponent(props){
    return (
        <>
            <Link
                to = '/myBlog'
                style = {{
                    'position': 'absolute',
                    'top':'1rem',
                    'right': '5rem',
                    'height': '5%'
                }}>
                <Button ghost><Icon type = "rollback"/> 返回</Button>
            </Link>
            <div 
                className = {style.artContent}
                dangerouslySetInnerHTML = {{__html: marked(props.content, option)}}>
            </div>
            <div
                className = {style.artFooter}>
                    {props.previousBlog === '' ? <div></div> : <Link to = {'/myBlog/' + props.previousBlog.id}><Icon type = 'arrow-left'/>&nbsp;{props.previousBlog.title}</Link>}
                    {props.nextBlog === '' ? <div></div> : <Link to = {'/myBlog/' + props.nextBlog.id}>{props.nextBlog.title}&nbsp;<Icon type = 'arrow-right'/></Link>}
            </div>
        </>
    );
}

export default BlogComponent;