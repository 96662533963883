import React from 'react';
import axios from 'axios';
import { Row,Col } from 'antd';

import TagComponent from '../component/tags/tags';
import MyBlogComponent from '../component/myBlogs/myBlogs';
import BlogContainer from './blog';

class MyBlogContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            blogs: [],
            tags: [],
            allBlogs: [],
            selectBlog: false,
            blog: {},
            blogIndex: 0
        }
        this.getBlogs = this.getBlogs.bind(this);
        this.getTags = this.getTags.bind(this);
        this.handleCheckTag = this.handleCheckTag.bind(this);
        this.handleClickBlog = this.handleClickBlog.bind(this);
    }

    
    componentDidMount(){
        let that = this;
        this.getTags();
        this.getBlogs()
            .then(function(){
                let blogID = that.props.location.pathname.split('/').length > 2 ? that.props.location.pathname.split('/')[2] : '0' ;
                if(blogID !== '0'){
                    let selectedBlog = {};
                    let blogIndex = 0;
                    that.state.blogs.map(function(blog, index){
                        if(blog.id === blogID){
                            selectedBlog = blog;
                            blogIndex = index;
                        }
                        return 0;
                    });
                    that.handleClickBlog(selectedBlog, blogIndex);
                }
            });
        this.props.history.listen((url) => {
            let blogID = url.pathname.split('/').length > 2 ? url.pathname.split('/')[2] : '0' ;
            if(blogID !== '0'){
                let selectedBlog = {};
                let blogIndex = 0;
                that.state.blogs.map(function(blog, index){
                    if(blog.id === blogID){
                        selectedBlog = blog;
                        blogIndex = index;
                    }
                    return 0;
                });
                that.handleClickBlog(selectedBlog, blogIndex);
            }else{
                that.setState({
                    selectBlog: false,
                    blog: {},
                    previousBlog: {},
                    nextBlog: {},
                    blogIndex: 0
                });
            }
        });
    }

    componentWillUnmount(){
        this.setState = (state, callback) => {
            return ;
        }
    }

    getBlogs(){
        let that = this;
        return axios.get('/articles/myBlogs/blogs.json')
            .then(function(result){
                that.setState({
                    blogs: result.data.data,
                    allBlogs: result.data.data
                });
            })
    }

    getTags(){
        let that = this;
        axios.get('/articles/myBlogs/tags.json')
            .then(function(result){
                that.setState({
                    tags: result.data.tags
                });
            });
    }

    handleCheckTag(tagIndex, checked){
        let newTagState = Object.assign([], this.state.tags);
        newTagState[tagIndex].checked = checked;

        let selectedTags = [];
        newTagState.map(function(tag, index){
            if(tag.checked){
                selectedTags.push(tag.name);
            }
            return 0;
        });

        if(selectedTags.length > 0){
            let filtedBlogs = [];
            this.state.allBlogs.map(function(blog){
                let tagsLen = blog.tags.length;
                for(let i = 0; i < tagsLen; i++){
                    if(selectedTags.indexOf(blog.tags[i]) > -1){
                        filtedBlogs.push(blog);
                        return 0;
                    }
                }
                return 0;
            });
            this.setState({
                blogs: filtedBlogs,
                tags:newTagState
            });
        }else{
            this.setState({
                blogs: this.state.allBlogs,
                tags:newTagState
            });
        }
    }

    handleClickBlog(blog, index){
        this.setState({
            selectBlog: true,
            blog: blog,
            previousBlog: index === 0 ? '' : this.state.blogs[index - 1],
            nextBlog: index === this.state.blogs.length - 1 ? '' : this.state.blogs[index + 1],
            blogIndex: index
        });
    }

    render(){
        return (
        <Row
            style = {{'height':'100%'}}
            type = 'flex'
            justify = 'space-around'>
            {
                this.state.selectBlog ? <Col
                    span = {18}
                    style = {{
                        'height': '100%'
                    }}
                    ><BlogContainer
                        blog = {this.state.blog}
                        previousBlog = {this.state.previousBlog}
                        nextBlog = {this.state.nextBlog}
                        onClickBlog = {this.handleClickBlog}
                        blogIndex = {this.state.blogIndex}></BlogContainer></Col> : <>
                        <Col 
                            span = {4}
                            style = {{
                                'height': '100%',
                            }}>
                                <TagComponent
                                    tags = {this.state.tags}
                                    handleCheckTag = {this.handleCheckTag}></TagComponent>
                        </Col>
                        <Col
                            span={1}
                            style = {{
                                'height': '100%',
                                'width': '2px',
                                'backgroundImage': 'linear-gradient(0deg, rgba(192, 192, 192, 0.1), rgba(192, 192, 192, 1)50%, rgba(192, 192, 192, 0.1))'
                                
                            }}></Col>
                        <Col
                            span = {16}
                            style = {{
                                'height': '100%',
                                'padding': '0 1rem',
                                'paddingLeft': '3rem',
                                'overflow': 'auto'
                            }}>
                                <MyBlogComponent
                                    blogs = {this.state.blogs}
                                    onClickBlog = {this.handleClickBlog}></MyBlogComponent>
                        </Col>
                    </>
            }
        </Row>);
    }
}

export default MyBlogContainer;