import React from 'react';
import reducer from '../reducer/reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import PageContainer from './page';
import { HashRouter as Router } from 'react-router-dom';

const store = createStore(reducer);

class Index extends React.Component{

    render(){
        return(
            <Provider store = {store}>
                <Router>
                    <PageContainer></PageContainer>
                </Router>
            </Provider>
        )
    }
}

export default Index;