const GET_MENU = 'get_menu';
const SELECT_PAGE = 'select_page';

const initialState = {
    visible:true,
    atTop:true,
    selectedPage:'home',
    hasNavigation: false,
    navigatorData:{}
};

function getMenu(data){
    return {
        type: GET_MENU,
        navigatorData: data
    }
}

function selectPage(page){
    return {
        type:SELECT_PAGE,
        selectedPage:page
    }
}

function reducer(state, action){
    if(typeof state === 'undefined') state = initialState;
    switch(action.type){
        case GET_MENU:
            return Object.assign({}, state, {
                navigatorData: action.navigatorData,
                hasNavigation: true
            });
        case SELECT_PAGE:
            return Object.assign({}, state, {
                selectedPage: action.selectedPage
            });
        default:
            return state;
    }
}

export {
    reducer as default,
    selectPage,
    getMenu
}



