import React from 'react';
import { Menu, Icon, Spin } from 'antd';
import style from './navigator.module.css';
import { Link } from 'react-router-dom';

let waitStyle = {
    'height': window.innerHeight,
    'width':window.innerWidth,
    'position': 'fixed',
    'left':'-0px',
    'display':'flex',
    'justifyContent':'center',
    'alignItems':'center',
    'backgroundColor':'rgba(255,255,255,0.6)'
}

let menuStyle = {
    'background': 'transparent',
    'borderLeft': 'none'
}

let menuRightStyle = {
    'float': 'right',
    'width': '20rem',
    'textAlign': 'right',
    'height': window.innerHeight
}

let menuRightIconStyle = {
    'fontSize': '3rem'
}

let menuTopIconStyle = {
    'fontSize': '2rem'
}

function NavigatorComponent(props){

    if(!props.hasNavigation){
        return (
            <div style = {{...waitStyle}}>
                <Spin
                    size = 'large'
                    tip = '欢迎来到张啸岩的个人站~ Please wait a moment...'></Spin>
            </div>
        );
    }else{
        return (
            <Menu selectedKeys = {[props.selectedPage]}
                onClick = { (object) => props.onSelectPage(object.key)}
                mode = {props.selectedPage === 'home' ? 'vertical-right' : 'horizontal'}
                defaultSelectedKeys = {['home']}
                theme = 'dark'
                style = {props.selectedPage === 'home' ? { ...menuStyle, ...menuRightStyle} : {...menuStyle}}>
                {props.selectedPage !== 'home' &&
                    <Menu.Item
                        key='home'>
                        <Link to={'/'}>
                            <Icon type='home'></Icon>
                        </Link>
                    </Menu.Item>
                }
                {props.navigatorData.titleLink instanceof Array && props.navigatorData.titleLink.map(function(link, index){
                    return ( 
                        <Menu.Item 
                            key = {link.routeValue}>
                            <Link to={'/' + link.routeValue}>{link.linkName}</Link>
                        </Menu.Item>
                    )
                })}
                {props.navigatorData.socialLink instanceof Array && props.navigatorData.socialLink.map(function(link, index){
                    return (
                        <Menu.Item key = {link.linkName} className = { props.selectedPage === '' ? style.menuRightSocialLink : style.menuTopSocialLink}>
                            <a href = {link.href}
                            target = "_blank"
                            rel = "noopener noreferrer">
                                <Icon type = {link.type} 
                                    style = {props.selectedPage === '' ? {...menuRightIconStyle} : {...menuTopIconStyle}} />
                            </a>
                        </Menu.Item>
                    )
                })}
            </Menu>
        );
    }
}

export default NavigatorComponent;