import React from 'react';
import style from './myLife.module.css';

function MyLifeComponent(props){
    return (
      <div className = {style.content}>
        {
            props.data instanceof Array ? props.data.map(function(img, index){
                return <div
                            className = {style.imgBox}
                            key = {index}>
                              <img 
                                src = {img.imgURL} 
                                alt = {'抱歉，图片被吞啦~'}
                                onClick = {() => props.onCickImg(img)} />

                </div>
            }) : ''
        }
      </div>  
    );
}

export default MyLifeComponent;