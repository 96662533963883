import React from 'react';
import axios from 'axios';

import MyLifeComponent from '../component/myLife/myLife';
import LifeImgModalComponent from '../component/lifeImg/lifeImg';

class MyLifeContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: "",
            imgModalVisible: false,
            imgDetail:{}
        }
        this.getMyLifeImgs = this.getMyLifeImgs.bind(this);
        this.handleClickImg = this.handleClickImg.bind(this);
        this.handleClickModal = this.handleClickModal.bind(this);
    }

    componentDidMount(){
        this.getMyLifeImgs();
    }

    getMyLifeImgs(){
        let that = this;
        return axios.get('/articles/myLife/imgs.json')
            .then(function(result){
                that.setState({
                    data: result.data.data
                })
            })
    }

    handleClickImg(imgDetail){
        this.setState({
            imgModalVisible: true,
            imgDetail: imgDetail
        });
    }

    handleClickModal(){
        this.setState({
            imgModalVisible: false,
            imgDetail: {}
        });
    }

    render(){
        return(
            <>
                <MyLifeComponent
                    data = {this.state.data}
                    onCickImg = {this.handleClickImg}></MyLifeComponent>
                <LifeImgModalComponent
                    imgDetail = {this.state.imgDetail}
                    imgModalVisible = {this.state.imgModalVisible}
                    onClickCloseModal = {this.handleClickModal}></LifeImgModalComponent>    
            </>
        )
    }
}

export default MyLifeContainer;