import React from 'react';
import { Link } from 'react-router-dom';

import style from './myBlogs.module.css';

function MyBlogComponent(props){
    return(
        <>
            {
                props.blogs instanceof Array && props.blogs.map(function(blog, index){
                    return(
                        <div
                            className = {style.blogBox}
                            key = {index}>
                            <Link to = {'/myBlog/' + blog.id}>
                                <h2 onClick = {() => props.onClickBlog(blog, index)}>
                                    {blog.title}
                                </h2>
                            </Link> 
                            <div
                                className = {style.tags}>
                                <label>{blog.date}</label>
                                <label>{blog.tags.join('、')}</label>
                            </div>
                            <div>
                                <span>" <i>{blog.desc}</i> "</span>
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
}

export default MyBlogComponent;