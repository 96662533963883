import React from 'react';
import axios from 'axios';

import BlogComponent from '../component/blog/blog';

class BlogContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            content: ''
        }
    }

    componentDidMount(){
        let that = this;
        axios.get('/articles/myBlogs/content/' + this.props.blog.id + '.md')
            .then(function(result){
                that.setState({
                    content: result.data 
                });
            })
    }
    componentWillReceiveProps(nextProps){
        let that = this;
        if(this.props.blog.id !== nextProps.blog.id){
            axios.get('/articles/myBlogs/content/' + nextProps.blog.id + '.md')
            .then(function(result){
                that.setState({
                    content: result.data 
                });
            })
        }
        
    }
    
    render(){
        return(
            <BlogComponent
                content = {this.state.content}
                {...this.props}></BlogComponent>
        )

    };
}

export default BlogContainer;