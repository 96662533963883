import React from 'react';
import IndexComponent from '../component/index/index';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import { selectPage } from '../reducer/navigator';

class PageContainer extends React.Component{

    constructor(props){
        super(props);
        this.initPage = this.initPage.bind(this);
    }

    componentWillMount(){
        this.initPage((this.props.location.pathname.split('/')[1] === '' ) ? 'home' : this.props.location.pathname.split('/')[1]);
    }
    
    componentDidUpdate(){
        this.initPage((this.props.location.pathname.split('/')[1] === '' ) ? 'home' : this.props.location.pathname.split('/')[1]);
    }

    initPage(routePage){
        this.props.onSelectPage(routePage);
    }

    render(){
        return(
            <IndexComponent 
                selectedPage = {this.props.selectedPage}></IndexComponent>
        )
    }
}

const mapStateToProps = state =>({
    selectedPage: state.navigatorReducer.selectedPage
})

const mapDispatchToProps = dispatch =>({
    onSelectPage: page => {dispatch(selectPage(page))}
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withRouter(PageContainer));