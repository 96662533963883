import React from 'react';
import classNames from 'classnames'
import { Route } from 'react-router-dom';

import style from './index.module.css';
import { Layout } from 'antd';

import Navigator from '../../container/navigator';
import AboutMeContainer from '../../container/aboutMe';
import MyBlogContainer from '../../container/myBlog';
import MyLifeContainer from '../../container/myLife';

const { Header, Content, Footer} = Layout;

function IndexComponent(props){
    const {
        selectedPage
    } = props;
    return(

        <Layout className =  {classNames({
                                    [style.index]:true,
                                    [style.tranparentBackground]:selectedPage === 'home' ? true : false,
                                    [style.deepBackground]: selectedPage !== 'home' ? true : false})}>
                <Header className = {style.header}>
                    <Navigator></Navigator>
                </Header>
                <Content>
                    <Route path = '/'></Route>
                    <Route path = '/aboutMe' component = {AboutMeContainer}></Route>
                    <Route path = '/myBlog' component = {MyBlogContainer}></Route>
                    <Route path = '/myLife' component = {MyLifeContainer}></Route>
                </Content>
                <Footer className = {style.footer}>
                    <span> 张啸岩的个人域  Powered by <a href="https://react.docschina.org/" target="_blank" rel="noopener noreferrer">@React</a> </span>
                </Footer>
            </Layout>

    )
};

export default IndexComponent;