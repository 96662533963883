import React from 'react';
import {Avatar} from 'antd';
import style from './aboutMe.module.css';

function AboutMeComponent(props){
    return (
        <div className = {style.content}>
            <div className = {style.profileAvatar}>
                <Avatar 
                    size = {300}
                    src = {props.avatarSrc}
                    shape = 'circle'></Avatar>
            </div>
            <div className = {style.profileContent}>
                {props.content instanceof Array ? props.content.map(function(item, index){
                    return (
                        <div key = {index}>
                            <h1>{item.title}</h1>
                            <p>{item.description}</p>
                        </div>
                    )
                }) : ''}
            </div>
        </div>
    );
}

export default AboutMeComponent;