import React from 'react';
import { Modal, Row, Col } from 'antd';

import style from './lifeImg.module.css'; 

const modalStyle = {
}

const modalBodyStyle = {
    'maxHeight': '40rem'
}

function lifeImgComponent(props){
    return (
        <Modal
            visible = {props.imgModalVisible}
            title = {props.imgDetail.title}
            centered = {true}
            footer = {null}
            width = '80%'
            style = {modalStyle}
            bodyStyle = {modalBodyStyle}
            onCancel = {props.onClickCloseModal}>
            <Row>
                <Col span = {18} 
                     style = {{
                         'display':'flex',
                         'justifyContent':'center'
                         }}>
                    <img className = {style.img}
                        src = {props.imgDetail.imgURL} 
                        alt="抱歉，图片被吞啦~"/>
                </Col>
                <Col span = {6}>
                    <p className = {style.desc}>{props.imgDetail.desc}</p>     
                </Col>
            </Row>
        </Modal>
    )
}

export default lifeImgComponent;