import React from 'react';
import axios from 'axios';
import AboutMeComponent from '../component/aboutMe/aboutMe';

class AboutMeContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            avatarSrc:'',
            content:''
        };
        this.getAboutMeData = this.getAboutMeData.bind(this);
    }

    componentDidMount(){
        this.getAboutMeData();
    }

    getAboutMeData(){
        let that = this;
        axios.get('/configure/aboutMe.json')
            .then(function(result){
                that.setState({
                    avatarSrc: result.data.avatarSrc,
                    content:result.data.content
                });
            })
    }
    render(){
        return <AboutMeComponent
                    avatarSrc = {this.state.avatarSrc}
                    content = {this.state.content}></AboutMeComponent>
    }
}

export default AboutMeContainer;