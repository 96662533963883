import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { getMenu, selectPage } from '../reducer/navigator';

import NavigatorComponent from '../component/navigator/navigator';

class Navigator extends React.Component{
    constructor(props){
        super(props);
        this.getNavigator = this.getNavigator.bind(this);
        this.handleSelectPage = this.handleSelectPage.bind(this);
    }

    componentDidMount(){
        this.getNavigator();
    }

    getNavigator(){
        let that = this;
        axios.get('/configure/navigator.json')
            .then(function(result){
                that.props.onGetMenu(result.data);
            })
            .catch(function(){

            })
    }

    handleSelectPage(routeValue){
        if(this.props.navigatorData.titleLink.some( item => {return item.routeValue === routeValue}) || routeValue === 'home'){
            this.props.onSelectPage(routeValue);
        }
    }

    render(){
        return <NavigatorComponent
                visible = {this.props.visible}
                atTop = {this.props.atTop}
                selectedPage = {this.props.selectedPage}
                navigatorData = {this.props.navigatorData}
                onSelectPage = {this.handleSelectPage}
                hasNavigation = {this.props.hasNavigation}></NavigatorComponent>
    }    
}
const mapStateToProps = state => ({
        visible: state.navigatorReducer.visible,
        atTop: state.navigatorReducer.atTop,
        selectedPage: state.navigatorReducer.selectedPage,
        navigatorData: state.navigatorReducer.navigatorData,
        hasNavigation: state.navigatorReducer.hasNavigation
});

const mapDispatchToProps = dispatch => ({
    onSelectPage: page => {dispatch(selectPage(page))},
    onGetMenu: menuData => {dispatch(getMenu(menuData))}
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigator);