import React from 'react';
import { Tag } from 'antd';

import style from './tags.module.css';

const { CheckableTag } = Tag;

function TagsComponent(props){
    return (
        <div className = {style.tagContent}>
            {props.tags instanceof Array && props.tags.map(function(tag, index){
                return(<CheckableTag
                    key = {index}
                    checked = {props.tags[index].checked}
                    onChange = {(checked) => props.handleCheckTag(index, checked)}>{tag.name}</CheckableTag>)
            })}
        </div>
    );
}

export default TagsComponent;